<template>
</template>

<script setup>
useHead({
	link: [
		{
			rel: "canonical",
			href: "https://www.minecraftiplist.com",
		},
	],
});

definePageMeta({
	layout: "serverlist",

})
</script>